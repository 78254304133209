<template>
    <div id="app">
       <div v-if="errorMessage" style="color: red;">{{ errorMessage }}</div>
       <div v-if="user && !forceChangePassword">
           <h1 class="mainHeadline">Welcome {{ user.username }}</h1>
           <button class="logoutButton" @click="logout">Logout</button>
           <NotificationsManager ref="notificationsManager"
                                 @fetchingNotifications="handleFetchingNotifications"
                                 @deletingNotification="handleDeletingNotification"
                                 @upsertingNotification="handleUpsertingNotification"/>
       </div>
       <div v-else-if="forceChangePassword">
           <form @submit.prevent="changePassword" style="display: flex; flex-direction: column; align-items: center;">
               <input v-model="newPassword" type="password" placeholder="New Password">
               <input v-model="confirmPassword" type="password" placeholder="Confirm New Password">
               <button type="submit">Change Password</button>
           </form>
       </div>
       <div v-else>
           <form @submit.prevent="login" style="display: flex; flex-direction: column; align-items: center;">
               <input v-model="username" type="text" placeholder="Username">
               <input v-model="password" type="password" placeholder="Password">
               <button type="submit">Login</button>
           </form>
       </div>
       <WaitingOverlay v-if="waitingOverlayVisible" />
   </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import NotificationsManager from './components/NotificationsManager.vue';
import WaitingOverlay from './components/WaitingOverlay.vue'; // Import WaitingOverlay component

export default {
   data() {
       return {
           user: null,
           username: '',
           password: '',
           newPassword: '',
           confirmPassword: '',
           forceChangePassword: false,
           errorMessage: '',
           waitingOverlayVisible: false, // Add waitingOverlayVisible data property
       };
   },
   mounted () {
       document.title = "BSA Notifications Manager";
   },
    components: {
       NotificationsManager,
       WaitingOverlay, // Register WaitingOverlay component
    },
   methods: {
    refreshContent() {
        this.$refs.notificationsManager.fetchNotifications().then(notifications => {
            this.$refs.notificationsManager.notifications = notifications;
        });
    },

    handleFetchingNotifications(isFetching) {
        this.waitingOverlayVisible = isFetching;
    },
    handleDeletingNotification(isDeleting) {
        this.waitingOverlayVisible = isDeleting;

        if (!isDeleting)
            this.refreshContent();
    },
    handleUpsertingNotification(isUpserting) {
        this.waitingOverlayVisible = isUpserting;

        if (!isUpserting)
            this.refreshContent();
    },
       async login() {
           this.waitingOverlayVisible = true; // Set waitingOverlayVisible to true to show WaitingOverlay
           try {
                this.waitingOverlayVisible = false; 
               const user = await Auth.signIn(this.username, this.password);
               this.user = user;
               if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                   this.forceChangePassword = true;
               } 
           } catch (error) {
               this.errorMessage = error.message;
           } finally {
               this.waitingOverlayVisible = false; // Set waitingOverlayVisible to false to hide WaitingOverlay
           }
           return this.user;
       },
       async changePassword() {
           this.waitingOverlayVisible = true; // Show WaitingOverlay
           if (this.newPassword !== this.confirmPassword) {
               this.errorMessage = 'Passwords do not match';
               this.waitingOverlayVisible = false; // Hide WaitingOverlay
               return;
           }
           try {
               const user = await Auth.completeNewPassword(this.user, this.newPassword);
               this.user = user;
               this.forceChangePassword = false;
           } catch (error) {
               this.errorMessage = error.message;
           } finally {
               this.waitingOverlayVisible = false; // Hide WaitingOverlay
           }
       },
       logout() {
           this.waitingOverlayVisible = true; // Show WaitingOverlay
           Auth.signOut();
           this.user = null;
           this.waitingOverlayVisible = false; // Hide WaitingOverlay
       },
   },
   async created() {
       this.waitingOverlayVisible = true; // Show WaitingOverlay while checking authentication
       try {
           this.user = await Auth.currentAuthenticatedUser();
           this.forceChangePassword = this.user.challengeName === 'NEW_PASSWORD_REQUIRED';
       } catch (error) {
           // console.error(error);
       } finally {
           this.waitingOverlayVisible = false; // Hide WaitingOverlay after checking
       }
   },
};
</script>


<style>
html {
    background: #161617;
    color: #FEFEFE;
    font-family: 'Roboto', sans-serif;
}

.mainHeadline{
    display: inline-block;
    width: 90%;
    padding-left: 5rem;
    padding-top: 1.5rem;
}

.logoutButton {
    color: #7E7E7E;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    border: none;
}
</style>